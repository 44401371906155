<template>
  <transition name="fast-fade-general">
    <div
      v-if="$store.state.fastCarregando"
      class="fast-loading-general"
    >
      <div class="fast-loading-logo">
        <img v-lazy="imageLoading">
      </div>
      <div class="fast-loading-spinner">
        <div class="bounce1" />
        <div class="bounce2" />
        <div class="bounce3" />
      </div>
    </div>
  </transition>
</template>

<script>
import { settings } from "@/config";

export default {
  name: "FastLoading",
  data() {
    return {
      imageLoading: "",
    };
  },
  computed: {},
  created() {},
  mounted() {
    settings.fastLoading().then((e) => (this.imageLoading = e.default));
  },
  methods: {},
};
</script>

<style>
</style>
